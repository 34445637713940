import React from "react";
import {Button, Col, Form, Row} from "react-bootstrap";
import {TextField} from "@mui/material";
import {ServiceManager} from "../service/ServiceManager";
import {toast} from "react-toastify";

export interface AstrologerState {
    username: string;
    name: string;
    education: string;
    experience: number;
    skills: number[];
    description: string;
    etc: string;
    image: string;
    rate_per_minute: number;
}

export default class AddAstrologerComponent extends React.Component<any, AstrologerState> {

    constructor(props: any) {
        super(props);
        this.state = {
            description: "",
            education: "",
            etc: "",
            experience: 0,
            image: "",
            name: "",
            rate_per_minute: 0,
            skills: [],
            username: ""
        }
        this.handleChange = this.handleChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
    }

    render() {
        return <div>
            <Form className="p-2">
                <Row className="p-2">
                    <Col>
                        <TextField label="username" name="username" value={this.state.username}
                                   onChange={this.handleChange}/>
                    </Col>
                    <Col>
                        <TextField label="Name" name="name" value={this.state.name}
                                   onChange={this.handleChange}/>
                    </Col>

                    <Col>
                        <TextField label="rate_per_minute" name="rate_per_minute" value={this.state.rate_per_minute}
                                   onChange={this.handleChange}/>
                    </Col>
                    <Col>
                        <TextField label="education" name="education" value={this.state.education}
                                   onChange={this.handleChange}/>
                    </Col>
                </Row>
                <Row className="p-2">

                    <Col>
                        <TextField label="Experience" name="experience" value={this.state.experience}
                                   onChange={this.handleChange}/>
                    </Col>
                    <Col>
                        <TextField label="skills" name="skills" value={this.state.skills}
                                   onChange={this.handleChange}/>
                    </Col>
                    <Col>
                        <TextField label="etc" name="etc" value={this.state.etc}
                                   onChange={this.handleChange}/>
                    </Col>
                    <Col>
                        <TextField label="Image" placeholder="Display Image" type="file"
                                   name="image" value={this.state.image} onChange={this.handleChange}
                                   fullWidth InputLabelProps={{shrink: true,}} className="mb-5"/>
                    </Col>
                </Row>
                <Row className="p-2">

                    <Col>
                        <Button type="button" onClick={this.handleSubmit}>Create Astrologer</Button>
                    </Col>
                </Row>
            </Form>
        </div>;
    }

    private handleChange = (e: any) => {
        e.preventDefault();
        this.setState({[e.target.name]: e.target.value} as unknown as AstrologerState);
    }
    private handleSubmit = () => {
        ServiceManager.getInstance().registerAstrologer(this.state)
            .then(resp => resp.status === 200 ? toast.success(resp.data) : toast.error(resp.data))
            .catch(reason => toast.error(reason));
    }
}
