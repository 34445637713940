import React from "react";
import {Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow} from "@mui/material";
import {Astrologer} from "../model/Astrologer";
import {Button} from "react-bootstrap";
import {ServiceManager} from "../service/ServiceManager";

export default class DeleteAstrologers extends React.Component<DeleteAstrologersProps, DeleteAstrologersState> {
    constructor(props: DeleteAstrologersProps) {
        super(props);
        this.state = { astrologers: []};
        this.deleteAstrologer = this.deleteAstrologer.bind(this);
    }


    private deleteAstrologer(evt: any, astrologer: Astrologer) {
        evt.preventDefault();
        return ServiceManager.getInstance().deleteAstrologer(astrologer.id);
    }

    componentDidMount() {
        ServiceManager.getInstance().astrologersForDeletion().then(response => {
                this.setState({astrologers: response.data});
                console.log(response.data);
            }
        );
    }

    render() {
        return (
            <div>
                <h1>Delete Astrologers</h1>
                <TableContainer component={Paper}>
                    <Table>
                        <TableHead>
                            <TableCell>ID</TableCell>
                            <TableCell>User ID</TableCell>
                            <TableCell>Name</TableCell>
                            <TableCell>Verified</TableCell>
                        </TableHead>
                        <TableBody>
                            {this.state.astrologers.map((astrologer) => (
                                <TableRow key={astrologer.id}>

                                    <TableCell>{astrologer.id}</TableCell>
                                    <TableCell>{astrologer.id}</TableCell>
                                    <TableCell>{astrologer.name}</TableCell>
                                    <TableCell>
                                        <Button onClick={e => this.deleteAstrologer(e, astrologer)}>Delete</Button>
                                    </TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>
            </div>
        );
    }


}

export interface DeleteAstrologersProps {
}

export interface DeleteAstrologersState {
    astrologers: Astrologer[];
}
