import React from "react";
import {Nav, NavLink} from "react-bootstrap";
import {AdminState} from "./RouterComponent";

export default class SideBar extends React.Component<AdminState, any> {

    constructor(prop: AdminState) {
        super(prop);
        this.state = {};
    }

    render() {
        return <div className="sidebar" data-color="orange">
            <div className="sidebar-wrapper">
                <div className="logo d-flex align-items-center justify-content-start">
                    <div className="logo-img">
                        <img src={require("../assets/img/logo.png")} alt="..."/>
                    </div>
                    <a
                        href="/profile"
                        className="simple-text logo-mini mx-1">
                        {this.props.username}
                        <span>{this.props.role}</span>
                    </a>

                </div>
                <Nav>
                    <li>
                        <NavLink>
                            Order History
                        </NavLink>
                    </li>

                    <li>
                        <NavLink href="/customer/wallet/recharge">
                            Recharge Wallet
                        </NavLink>
                    </li>
                    <li>
                        <NavLink href="/customer/chat">
                            Chat with Customer
                        </NavLink>
                    </li>
                    <NavLink href="/astrologer/chat">
                        Astrologer Chat
                    </NavLink>
                    <li>
                        <NavLink href="/astrologer/add">
                            Onboard Astrologer
                        </NavLink>
                    </li>
                    <li>
                        <NavLink href="/customer/add">
                            Onboard Customer
                        </NavLink>
                    </li>
                    <li>
                        <NavLink href="/employee/add">
                            Onboard Employee
                        </NavLink>
                    </li>
                    <li>
                        <NavLink>
                            Refund
                        </NavLink>
                    </li>
                    <li>
                        <NavLink>
                            Manage Astrologer
                        </NavLink>
                    </li>
                    <li>
                        <NavLink href="/analytics">
                            Analytics
                        </NavLink>
                    </li>
                    <li>
                        <NavLink href="/analytics">
                            Money Loaded
                        </NavLink>
                    </li>
                    <li>
                        <NavLink href="/pooja/add">
                            Puja add
                        </NavLink>
                    </li>
                    <li>
                        <NavLink href="/pooja/list">
                            Puja List
                        </NavLink>
                    </li>
                    <li>
                        <NavLink href="/pooja/edit">
                            Puja Edit
                        </NavLink>
                    </li>
                </Nav>
            </div>
        </div>;
    }
}
