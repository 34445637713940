import React from "react";
import {Chart} from "react-google-charts";
import {Col, Row} from "react-bootstrap";

export default class AnalyticsComponent extends React.Component<any, any> {


    render() {
        return <div>
            <Row>
                <Chart chartType="BarChart" width="100%" height="400px" data={data} options={options}/>
            </Row>

            <Row>
                <Col>
                    <Chart chartType="BarChart" width="100%" height="400px" data={data} options={optionsOnline}/>
                </Col>
                <Col>
                    <Chart chartType="BarChart" width="100%" height="400px" data={data}
                           options={optionsCallInProgress}/>
                </Col>
            </Row>
            <Row>
                <Chart chartType="BarChart" width="100%" height="400px" data={data} options={options}/>
            </Row>
        </div>;
    }
}
export const data = [
    ["City", "2010 Population", "2000 Population"],
    ["New York City, NY", 8175000, 8008000],
    ["Los Angeles, CA", 3792000, 3694000],
    ["Chicago, IL", 2695000, 2896000],
    ["Houston, TX", 2099000, 1953000],
    ["Philadelphia, PA", 1526000, 1517000],
];

export const options = {
    title: "Call in last 24 hours",
    chartArea: {width: "50%"},
    hAxis: {
        title: "Total Population",
        minValue: 0,
    },
    vAxis: {
        title: "City",
    },
};

export const optionsCallInProgress = {
    title: "Call in Progress ",
    chartArea: {width: "50%"},
    hAxis: {
        title: "Total Population",
        minValue: 0,
    },
    vAxis: {
        title: "City",
    },
};
export const optionsOnline = {
    title: "Online Astrologer Count",
    chartArea: {width: "50%"},
    hAxis: {
        title: "Total Population",
        minValue: 0,
    },
    vAxis: {
        title: "City",
    },
};
