import React from "react";
import {ServiceManager} from "../service/ServiceManager";

export default class PaymentGatewayComponent extends React.Component<PgProps, PgState> {

    constructor(props: PgProps) {
        super(props);
        this.state = {pgPayLoad: {request: "", xVerify: ""}};
    }

    componentDidMount() {
        ServiceManager.getInstance().pgDetails().then(pgState => {
            this.setState({pgPayLoad: pgState.data});
        })
    }

    render() {
        return <div>
            <button className="btn-primary" onClick={this.handleSubmit}>Click to Pay</button>
        </div>;
    }

    private handleSubmit = (evt: any) => {
        evt.preventDefault();
        //ServiceManager.getInstance().pay();
    }
}

export interface PgProps {

}

export interface PgState {
    pgPayLoad: PgPayload;
}

export interface PgPayload {
    xVerify: string;
    request: string;
}
