import React from "react";
import {Button, Card, Col, Container, Form, Navbar, Row} from "react-bootstrap";
import {TextField} from "@mui/material";
import {ServiceManager} from "../service/ServiceManager";
import axios from "axios";
import {toast, ToastContainer} from "react-toastify";
import logo from "../assets/img/logo.png";

export default class LoginComponent extends React.Component<LoginProps, LoginState> {


    constructor(props: LoginProps) {
        super(props);
        this.state = {
            username: "",
            password: ""
        }
        this.handleSubmit = this.handleSubmit.bind(this);
        this.handleChange = this.handleChange.bind(this);
    }


    handleSubmit = (e: any) => {
        //localStorage.setItem("Authorization", `Bearer ${res.data}`)
        e.preventDefault();
        ServiceManager.getInstance().login(this.state)
            .then(resp => {
                if (resp.status === 200) {
                    const token = resp.data.token;
                    axios.defaults.headers.common['Authorization'] = `Bearer ${token}`;
                    localStorage.setItem("Authorization", `Bearer ${token}`)
                    localStorage.setItem("Username", resp.data.username);
                    toast.success("Success")
                    window.location.replace("/");
                }
            }).catch(reason => {
            console.log(reason)
            toast.error(reason.response.data);
        })
    }

    handleChange = (e: any) => {
        e.preventDefault();
        console.log(e.target.name)
        this.setState({[e.target.name]: e.target.value} as unknown as LoginState)

    }

    render(): React.ReactNode {
        return (
            <div>
                <Navbar bg="light" expand="lg" className="bg-body-tertiary">
                    <Container>
                        <div className="d-flex justify-content-center align-items-center ml-2 ml-lg-0">

                            <Navbar.Brand
                                href="/home"
                                className="mr-2">
                                <img src={logo} alt="" width="32" height="32"/>
                                <span className="p-2">Astroconsult</span>
                            </Navbar.Brand>

                        </div>
                    </Container>
                </Navbar>
                <Row className="justify-content-md-center mt-4">
                    <Col>

                    </Col>
                    <Col>
                        <Card>
                            <Card.Header>Login</Card.Header>
                            <Card.Body>
                                <Form onSubmit={this.handleSubmit}>
                                    <div>
                                        <TextField label="Username" name="username" value={this.state.username}
                                                   onChange={this.handleChange} placeholder="Phone number"
                                                   type="phonenumber"
                                                   fullWidth InputLabelProps={{shrink: true,}} className="mb-5"/>
                                        <TextField label="Password" name="password" value={this.state.password}
                                                   onChange={this.handleChange} placeholder="Password" type="password"
                                                   fullWidth InputLabelProps={{shrink: true,}} className="mb-5"/>
                                        <div className="d-flex justify-content-end mt-4">
                                            <Button variant="primary" size="lg" className="px-60" type="submit"
                                                    onClick={this.handleSubmit}>Login</Button>
                                        </div>
                                    </div>
                                </Form>
                            </Card.Body>
                        </Card>
                    </Col>
                    <Col></Col>
                </Row>
            </div>
        );
    }
}

export interface LoginProps {


}

export interface LoginState {
    username: string;
    password: string;
}

export interface AuthToken {
    token: string;
    profileComplete: boolean;
    username: string;
}
