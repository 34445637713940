import React from "react";
import { Button } from "react-bootstrap";
import { ServiceManager } from "../../../service/ServiceManager";
import { toast } from "react-toastify";

export default class ManageCustomerApp extends React.Component<any, any>{

    constructor(props:any){
        super(props);
        this.upload = this.upload.bind(this);
    }

    render(): React.ReactNode {
        return <div>
            <h3>Upload banner Image</h3>
            <form>
                <input type="file" onChange={this.upload} />
            </form>

            <h3>List banner images</h3>
        </div>
    }

    upload = (event: any) => {
        event.preventDefault();
        const file = event.target.files[0];
        const formData: FormData = new FormData();
        formData.append("file", file);
        ServiceManager.getInstance().upload(formData).then(resp => { 
            toast.success("Success")
        }).catch(err => {
            console.log(err);
            toast.error("Error");
         })
    }
}

export interface ManageCustomerState {

}
