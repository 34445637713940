import {AuthToken, LoginState} from "../login/LoginComponent";
import axios, {AxiosResponse} from "axios";
import {AdminState} from "../routing/RouterComponent";
import {OrderHistoryState} from "../order/OrderHistoryComponent";
import {PujaState} from "../puja/OnlinePujaComponent";
import {PujaList, SearchPayload} from "../puja/OnlinePujaListComponent";
import {AstrologerState} from "../onboarding/AddAstrologerComponent";
import {CustomerState} from "../onboarding/AddCustomerComponent";
import {PgPayload, PgState} from "../pg/PaymentGatewayComponent";

import {Astrologer} from "../model/Astrologer";
import {Page} from "../model/Page";
import Customer from "../model/Customer";

import {RechargeRequest, RechargeResponse} from "../wallet/RechargeWalletComponent";
import {OrderHistory} from "../chat/OrderHistory";

export class ServiceManager {


    private static instance: ServiceManager = new ServiceManager();

    public static getInstance(): ServiceManager {
        axios.defaults.baseURL = "https://api.astroconsult.live";
        //axios.defaults.baseURL = "http://localhost:8080";
        //axios.defaults.httpsAgent.rejectUnauthorized = false;
        return ServiceManager.instance;
    }

    login(ls: LoginState): Promise<AxiosResponse<AuthToken>> {
        // need to reset the headers
        return axios.post<LoginState, AxiosResponse<AuthToken>>("/auth/v1/verify", ls, {
            headers: {}
        });
    }

    logout() {
        localStorage.removeItem("Authorization")
        localStorage.removeItem("Username")
        localStorage.clear();
    }

    something() {
        axios.defaults.headers.common['Authorization'] = localStorage.getItem("Authorization");
    }

    isAuthenticated(): Promise<AxiosResponse<AdminState>> {
        axios.defaults.headers.common['Authorization'] = localStorage.getItem("Authorization");
        return axios.get<any, AxiosResponse<AdminState>>("/auth/v1/authenticated");
    }

    getOderHistory(): Promise<AxiosResponse<OrderHistoryState>> {
        axios.defaults.headers.common['Authorization'] = localStorage.getItem("Authorization");
        return axios.get<any, AxiosResponse<OrderHistoryState>>("api/v1/admin/order/history");
    }

    // puja  related stuff
    addPuja(pujaState: PujaState): Promise<AxiosResponse<PujaState>> {
        axios.defaults.headers.common['Authorization'] = localStorage.getItem("Authorization");
        return axios.post<PujaState, AxiosResponse<PujaState>>("/api/core/online/puja/add", pujaState);
    }

    listPuja(): Promise<AxiosResponse<PujaList>> {
        axios.defaults.headers.common['Authorization'] = localStorage.getItem("Authorization");
        return axios.get<any, AxiosResponse<PujaList>>("/api/core/online/puja/list");
    }

    sliceListPuja(page: number): Promise<AxiosResponse<PujaList>> {
        axios.defaults.headers.common['Authorization'] = localStorage.getItem("Authorization");
        return axios.get<any, AxiosResponse<PujaList>>("/api/core/online/puja/list/" + page);
    }

    findPujaById(id: number | undefined): Promise<AxiosResponse<PujaState>> {
        axios.defaults.headers.common['Authorization'] = localStorage.getItem("Authorization");
        return axios.get<any, AxiosResponse<PujaState>>("/api/core/online/puja/" + id);
    }

    searchPuja(searchPayload: SearchPayload): Promise<AxiosResponse<PujaList>> {
        axios.defaults.headers.common['Authorization'] = localStorage.getItem("Authorization");
        return axios.post<SearchPayload, AxiosResponse<PujaList>>("/api/core/online/puja/search", searchPayload);
    }

    registerAstrologer(state: AstrologerState): Promise<AxiosResponse<string>> {
        return axios.post<AstrologerState, AxiosResponse<string>>("/auth/v1/register/astrologer", state);
    }

    registerCustomer(state: CustomerState): Promise<AxiosResponse<string>> {
        return axios.post<CustomerState, AxiosResponse<string>>("/auth/v1/register/customer", state);
    }

    // CHAT  INFORMATION
    // getChannel(): Promise<AxiosResponse<ChannelResponse>> {
    //     axios.defaults.headers.common['Authorization'] = localStorage.getItem("Authorization");
    //     return axios.get<any, AxiosResponse<ChannelResponse>>("/api/core/astrologer/channel");
    // }
    //
    // getCustomerChannel(): Promise<AxiosResponse<ChannelResponse>> {
    //     axios.defaults.headers.common['Authorization'] = localStorage.getItem("Authorization");
    //     return axios.get<any, AxiosResponse<ChannelResponse>>("/api/core/customer/channel");
    // }

    // Management section 

    upload(formData: FormData): Promise<AxiosResponse<any>> {
        axios.defaults.headers.common['Authorization'] = localStorage.getItem("Authorization");
        return axios.post<FormData, AxiosResponse<any>>("/api/admin/management/app/customer/banner/upload", formData);
    }

    pgDetails(): Promise<AxiosResponse<PgPayload>> {
        axios.defaults.headers.common['Authorization'] = localStorage.getItem("Authorization");
        return axios.post<any, AxiosResponse<PgPayload>>("/api/core/pg/details");

    }


    pay(payLoad: PgPayload) {
        axios.defaults.headers.common['Authorization'] = localStorage.getItem("Authorization");
        return axios.post<any, AxiosResponse<any>>("https://api-preprod.phonepe.com/apis/pg-sandbox/pg/v1/pay", {request: payLoad.request},
            {
                headers: {
                    accept: 'text/plain',
                    'Content-Type': 'application/json',
                    'X-VERIFY': payLoad.xVerify,
                }
            });
    }

    csrf() {
        axios.defaults.headers.common['Authorization'] = localStorage.getItem("Authorization");
        return axios.get<any, AxiosResponse<Csrf>>("/csrf");
    }

    astrologers() {
        axios.defaults.headers.common['Authorization'] = localStorage.getItem("Authorization");
        return axios.get<any, AxiosResponse<Page<Astrologer>>>("/api/core/customer/astrologer/list");
    }


    getWaitList() {
        axios.defaults.headers.common['Authorization'] = localStorage.getItem("Authorization");
        return axios.get<any, AxiosResponse<OrderHistory[]>>("/api/core/astrologer/waitlist");

    }

    startChat(astrologerId: number) {
        axios.defaults.headers.common['Authorization'] = localStorage.getItem("Authorization");
        return axios.post<any, AxiosResponse<OrderHistory>>("/api/core/chat/start/" + astrologerId);
    }

    // onGoingChat() {
    //     axios.defaults.headers.common['Authorization'] = localStorage.getItem("Authorization");
    //     return axios.get<any, AxiosResponse<ChatMessage[]>>("/api/chat/astrologer/get/all/message");
    //
    // }

    onGoingOrder() {
        axios.defaults.headers.common['Authorization'] = localStorage.getItem("Authorization");
        return axios.get<any, AxiosResponse<OrderHistory>>("/api/core/order/astrologer/current");
    }


    onGoingCustomerOrder() {
        axios.defaults.headers.common['Authorization'] = localStorage.getItem("Authorization");
        return axios.get<any, AxiosResponse<OrderHistory>>("/api/core/order/customer/current");
    }

    pgStart(data: RechargeRequest) {
        axios.defaults.headers.common['Authorization'] = localStorage.getItem("Authorization");
        return axios.post<RechargeRequest, AxiosResponse<RechargeResponse>>("api/core/pg/customer/start", data)
    }

    // sendCustomerMessage(uo: MsgPayLoadUo) {
    //     axios.defaults.headers.common['Authorization'] = localStorage.getItem("Authorization");
    //     return axios.post<MsgPayLoadUo, AxiosResponse<ChatMessage[]>>("/api/core/chat/customer/post/message", uo);
    // }

    acceptChat(txId: string) {
        axios.defaults.headers.common['Authorization'] = localStorage.getItem("Authorization");
        return axios.post<any, AxiosResponse<OrderHistory>>("/api/core/chat/astrologer/accept", {txId: txId});
    }

    deleteAstrologer(id: number) {
        axios.defaults.headers.common['Authorization'] = localStorage.getItem("Authorization");
        return axios.get<any, AxiosResponse<Astrologer[]>>("/api/admin/astrologer/" + id);
    }

    astrologersForDeletion() {
        axios.defaults.headers.common['Authorization'] = localStorage.getItem("Authorization");
        return axios.get<any, AxiosResponse<Astrologer[]>>("/api/admin/astrologer");
    }
}

export interface Csrf {
    token: string
    parameterName: string
    headerName: string
}
