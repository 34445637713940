import React from "react";
import {ServiceManager} from "../service/ServiceManager";
import {BrowserRouter, Route, Routes} from "react-router-dom";
import LoginComponent from "../login/LoginComponent";
import OrderHistoryComponent from "../order/OrderHistoryComponent";
import OnlinePujaComponent from "../puja/OnlinePujaComponent";
import OnlinePujaListComponent from "../puja/OnlinePujaListComponent";
import SideBar from "./SideBar";
import AdminTopBar from "./AdminTopBar";
import AnalyticsComponent from "../management/analytics/AnalyticsComponent";


import SearchPujaComponent from "../puja/SearchPujaComponent";
import AddAstrologerComponent from "../onboarding/AddAstrologerComponent";
import AddCustomerComponent from "../onboarding/AddCustomerComponent";
import PrivacyPolicyComponent from "../web/PrivacyPolicyComponent";
import LicenseComponent from "../web/LicenseComponet";
import TnCComponent from "../web/TnCComponent";
import ManageCustomerApp from "../management/app/customer/ManageCustomerApp";
import PaymentGatewayComponent from "../pg/PaymentGatewayComponent";
import RechargeWalletComponent from "../wallet/RechargeWalletComponent";
import DeleteAstrologers from "../onboarding/DeleteAstrologers";


export default class RouterComponent extends React.Component<AdminProps, AdminState> {

    constructor(props: AdminProps) {
        super(props);
        this.state = {
            username: "",
            authenticated: false,
            role:""
        }
    }

    componentDidMount() {
        ServiceManager.getInstance().isAuthenticated().then(resp => {
            this.setState({authenticated: resp.data.authenticated});
            this.setState({username: resp.data.username});
            this.setState({role:resp.data.role})
        }).catch(reason => {
            this.setState({authenticated: false, username:"Dummy value"})
            //alert(reason)
            //toast.error("Please login");
        })
    }

    render(): React.ReactNode {
        return (
            <BrowserRouter>
                <div>
                    {
                        this.state.authenticated ?
                            <div className="wrapper">
                                <SideBar authenticated={this.state.authenticated} username={this.state.username} role={this.state.role}/>
                                <div className="main-panel">
                                    <AdminTopBar />
                                    <div className="content">
                                        <Routes>
                                            <Route element={<AddAstrologerComponent/>} path="/astrologer/add"/>
                                            <Route element={<AddAstrologerComponent/>} path="/astrologer/list"/>
                                            <Route element={<AddCustomerComponent/>} path="/customer/add"/>
                                            <Route element={<AddCustomerComponent/>} path="/customer/list"/>
                                            {/*<Route element={<CustomerChatComponent/>} path="/customer/chat"/>*/}
                                            <Route element={<OrderHistoryComponent/>} path="/order"/>
                                            <Route element={<ManageCustomerApp/>} path="/admin/customer/manage"/>

                                            <Route element={<AnalyticsComponent/>} path="/analytics"/>
                                            <Route element={<OnlinePujaComponent callback={()=>{}}/>} path="/pooja/add"/>
                                            <Route element={<OnlinePujaListComponent callback={()=>{}}/>} path="/pooja/list"/>
                                            <Route element={<SearchPujaComponent/>} path="/pooja/edit"/>

                                            {/*<Route element={<CustomerChatComponent/>} path="/customer/chat"/>*/}
                                            {/*<Route element={<CallComponent/>} path="/customer/call"/>*/}
                                            <Route element={<RechargeWalletComponent/>} path="/customer/wallet/recharge"/>
                                            <Route element={<OrderHistoryComponent/>} path="*"/>
                                            <Route element={<PrivacyPolicyComponent/>} path="/web/pp"/>
                                            <Route element={<TnCComponent/>} path="/web/tnc"/>
                                            <Route element={<LicenseComponent/>} path="/web/license"/>

                                             {/*Astrologer stuff*/}
                                            <Route element={<DeleteAstrologers/>} path="/astrologer/delete"/>
                                        </Routes>
                                    </div>
                                </div>
                            </div>
                            : <Routes>
                                <Route element={<PaymentGatewayComponent/>} path="/redirect/pg"/>
                                <Route element={<PrivacyPolicyComponent/>} path="/web/pp"/>
                                <Route element={<TnCComponent/>} path="/web/tnc"/>
                                <Route element={<LicenseComponent/>} path="/web/license"/>
                                <Route element={<LoginComponent/>} path="*"/>
                            </Routes>
                    }
                </div>
            </BrowserRouter>
        )
    }

}


interface AdminProps {
}

export interface AdminState {
    authenticated: boolean;
    username: string;
    role:string;
}
