import React from "react";
import {ServiceManager} from "../service/ServiceManager";
import {Button, Col, Row} from "react-bootstrap";

export default class RechargeWalletComponent extends React.Component<any, WalletState> {
    constructor(props: any) {
        super(props);
        this.state = {start: false, amount: 10000, url: ""}
        this.recharge=this.recharge.bind(this);
        this.handleAmountChange=this.handleAmountChange.bind(this);
        //this.=the..bind(this);
    }

    componentDidMount() {

    }

    render() {
        return <div>
            <Row>
                <Col>
                    <input type="number" value={this.state.amount} onChange={this.handleAmountChange}/>
                </Col>
                <Col>
                    <Button onClick={this.recharge}>Recharge</Button>
                </Col>
            </Row>
            {
                this.state.start ?
                    <embed src={this.state.url} height={800} /> : <div></div>
            }
        </div>
            ;
    }

    recharge(evt: any) {
        evt.preventDefault();
        ServiceManager.getInstance()
            .pgStart({amount: this.state.amount, callBackUrl: "https://astroconsult.live/pg/redirect"})
            .then(response => {
                const data = response.data;
                this.setState({url: data.url, start: true})
            });

    }

    handleAmountChange(evt: any) {
        evt.preventDefault();
        this.setState({start: false, amount: evt.target.value});
    }
}

export interface WalletState {
    amount: number;
    url: string;
    start: boolean;
}

export interface RechargeResponse {
    txId: string;
    url: string;
    method: string
}


export interface RechargeRequest {
    amount: number;
    callBackUrl: string;
}
