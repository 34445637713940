import React from "react";
import OnlinePujaComponent, {PujaProps, PujaState} from "./OnlinePujaComponent";
import {ServiceManager} from "../service/ServiceManager";
import {TextField} from "@mui/material";


export default class SearchPujaComponent extends React.Component<any, PujaState>{

    handleSearch = ()=> {
        ServiceManager.getInstance().findPujaById(1).then(resp => this.setState(resp.data));
    }

    render() {
        return <div>
            <h1>Search puja</h1>
            <div>
                <TextField label="Search puja"/>
            </div>
            <OnlinePujaComponent callback={()=>{}}/>

        </div>;
    }
}
