import React from "react";
import {Button, Col, Container, Form, Row} from "react-bootstrap";
import {Checkbox, FormControlLabel, TextField} from "@mui/material";
import {ServiceManager} from "../service/ServiceManager";
import {toast} from "react-toastify";

export interface MatchParams {
    name: string;
}
export interface PujaProps {
    id?: number|undefined;
    name?: string;
    subTitle?: string;
    duration?: string;
    description?: string;
    image?: string;
    price?: number;
    dateAndTime?: number;
    showBefore?: number;
    callback:Function;
}

export interface PujaState {
    id?: number;
    name: string;
    subTitle: string;
    duration: string;
    description: string;
    image: string;
    price: number;
    dateAndTime: number;
    showBefore: number;
}

export default class OnlinePujaComponent extends React.Component<PujaProps, PujaState> {

    constructor(props: PujaProps) {
        super(props);
        this.state = {
            dateAndTime: 1
            , description: "", duration: "1", image: "", name: "", price: 101, subTitle: "", showBefore: 30
        }

        this.handleSubmit = this.handleSubmit.bind(this);
    }

    componentDidMount() {
        if (typeof this.props.id === 'number') {
            ServiceManager.getInstance().findPujaById(this.props.id).then(resp => this.setState(resp.data));
        }
    }

    handleSubmit = (e: any) => {
        e.preventDefault();
        ServiceManager.getInstance().addPuja(this.state)
            .then(resp => {
                    resp.status === 200 ? toast.success("Puja added") : toast.error("Puja not added");
                    // @ts-ignore
                this.props.callback();
                }
            )

            .catch(error => toast.error(error));
    }

    handleChange = (e: any) => {
        e.preventDefault()
        alert(e.target.name +" --- "+e.target.value)
        this.setState({[e.target.name]: e.target.value} as unknown as PujaState)
    }

    handleDateTimeChange = (e:any)=>{
        e.preventDefault()
        let date:Date = new Date(e.target.value);
        alert(e.target.name +" --- "+date.getTime())
        this.setState({[e.target.name]: e.target.value} as unknown as PujaState)

    }

    render() {
        return (
            <Container>
                <h3>
                    Puja Details
                </h3>
                <Form className="p-2">
                    <Row>
                        <Col>
                            <TextField label="Name" placeholder="Pooja Name" name="name" value={this.state.name}
                                       onChange={this.handleChange}
                                       fullWidth InputLabelProps={{shrink: true,}} className="mb-5"/>
                        </Col>

                        <Col>
                            <TextField label="Description" placeholder="Pooja Description" name="description"
                                       value={this.state.description} onChange={this.handleChange}
                                       fullWidth InputLabelProps={{shrink: true,}} className="mb-5"/>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <TextField label="Subtitle" placeholder="Pooja Name" name="subTitle"
                                       value={this.state.subTitle} onChange={this.handleChange}
                                       fullWidth InputLabelProps={{shrink: true,}} className="mb-5"/>
                        </Col>

                        <Col>
                            <TextField label="Pricing" placeholder="Pricing in rupees" type="number"
                                       name="price" value={this.state.price} onChange={this.handleChange}
                                       fullWidth InputLabelProps={{shrink: true,}} className="mb-5"/>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <TextField label="Duration (minutes)" placeholder="Minutes" type="number"
                                       name="duration" value={this.state.duration} onChange={this.handleChange}
                                       fullWidth InputLabelProps={{shrink: true,}} className="mb-5"/>
                        </Col>

                        <Col>
                            <TextField label="Image" placeholder="Display Image" type="file"
                                       name="image" value={this.state.image} onChange={this.handleChange}
                                       fullWidth InputLabelProps={{shrink: true,}} className="mb-5"/>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <TextField label="Date & Time" placeholder="Date" type="datetime-local"
                                       name="dateAndTime" value={this.state.dateAndTime} onChange={this.handleDateTimeChange}
                                       fullWidth InputLabelProps={{shrink: true,}} className="mb-5"/>
                        </Col>
                        <Col>
                            <FormControlLabel control={<Checkbox defaultChecked/>} label="Show always"/>
                        </Col>
                        <Col>
                            <TextField label="Show before days" placeholder="Time" type="number" defaultValue="30"
                                       name="showBefore" value={this.state.showBefore} onChange={this.handleChange}
                                       fullWidth InputLabelProps={{shrink: true,}} className="mb-5"/>
                        </Col>
                    </Row>
                    <div className="d-flex justify-content-end mt-4">
                        <Button variant="primary" size="lg" className="px-60"
                                type="button" onClick={this.handleSubmit}>Save</Button>
                    </div>
                </Form>
            </Container>);
    }
}
