import React from 'react';
import ReactDOM from 'react-dom/client';

import reportWebVitals from './reportWebVitals';
import RouterComponent from "./routing/RouterComponent";
//css
import 'bootstrap/dist/css/bootstrap.min.css';
import "./assets/css/animate.min.css";
import "./assets/scss/light-bootstrap-dashboard-react.scss?v=2.0.0";
import "./assets/css/demo.css";
import "@fortawesome/fontawesome-free/css/all.min.css";
// Toast
import {ToastContainer} from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';
import {Container} from "react-bootstrap";
// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
    apiKey: "AIzaSyA8byAzHUtImrBYpDWayaAivHcvGxwNyYw",
    authDomain: "astroconsult-405710.firebaseapp.com",
    projectId: "astroconsult-405710",
    storageBucket: "astroconsult-405710.appspot.com",
    messagingSenderId: "246610423296",
    appId: "1:246610423296:web:26b765f4f41d52e4d0f2e5",
    measurementId: "G-Z5ZF0N7B4L"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);
const root = ReactDOM.createRoot(
    document.getElementById('root') as HTMLElement
);
root.render(
    <React.StrictMode>

            <ToastContainer position="top-center"
                            autoClose={2500}
                            newestOnTop={false}
                            closeOnClick
                            rtl={false}
                            pauseOnFocusLoss
                            draggable
                            pauseOnHover/>
            <RouterComponent/>

    </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
