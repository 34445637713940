import React from "react";
import {ServiceManager} from "../service/ServiceManager";
import {toast} from "react-toastify";
import {Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TextField} from "@mui/material";
import {Button, Row, Container,Form} from "react-bootstrap";

export interface OrderHistoryProp {
}

export interface OrderHistoryState {
    orderHistories: OrderHistory[];
}

export interface OrderHistory {
    id: number;
    creationTime: number;
    startTime: number;
    finishTime: number;
    billedUpto: number;
    lastUpdated: number;
    customer: string;
    astrologer: string;
    astrologerRate: number;
    amountConsumed: number;
    canContinue: boolean;
    customerName: string;
    customerImage: string;
    astrologerName: string;
    astrologerImage: string;
    reason: string;
    orderType: string;
    orderStatus: string;
}

export default class OrderHistoryComponent extends React.Component<OrderHistoryProp, OrderHistoryState> {

    constructor(prop: OrderHistoryProp) {
        super(prop);
        this.state = {
            orderHistories: [{
                amountConsumed: 0,
                astrologer: "",
                astrologerImage: "",
                astrologerName: "",
                astrologerRate: 0,
                billedUpto: 0,
                canContinue: false,
                creationTime: 0,
                customer: "",
                customerImage: "",
                customerName: "",
                finishTime: 0,
                id: 0,
                lastUpdated: 0,
                orderStatus: "",
                orderType: "",
                reason: "",
                startTime: 0
            }]
        };
    }

    componentDidMount() {
        let sm = ServiceManager.getInstance();
        sm.getOderHistory().then(resp => {
            this.setState(resp.data);
        }).catch(reason => toast.error(reason))
    }

    render() {
        return (
            <div className="wrapper">
                <h1>
                    Order history
                </h1>
                <Container fluid="md">

                    <Row className="justify-content-md-center">
                        <Form>
                            <TextField/><Button>Search</Button>
                        </Form>
                    </Row>
                    <Row className="justify-content-md-center">
                        <TableContainer component={Paper}>
                            <Table sx={{minWidth: 650}} aria-label="simple table">
                                <TableHead>
                                    <TableRow>
                                        <TableCell>Dessert (100g serving)</TableCell>
                                        <TableCell align="right">Name</TableCell>
                                        <TableCell align="right">Subtitle</TableCell>
                                        <TableCell align="right">Price</TableCell>
                                        <TableCell align="right">Duration</TableCell>
                                        <TableCell align="right">Description</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {
                                        this.state.orderHistories.map((row) =>
                                            (
                                                <TableRow
                                                    key={row.id}
                                                    sx={{'&:last-child td, &:last-child th': {border: 0}}}>
                                                    <TableCell component="th" scope="row">
                                                        {row.creationTime}
                                                    </TableCell>
                                                    <TableCell align="right">{row.amountConsumed}</TableCell>
                                                    <TableCell align="right">{row.customer}</TableCell>
                                                    <TableCell align="right">{row.astrologer}</TableCell>
                                                    <TableCell align="right">{row.id}</TableCell>
                                                    <TableCell align="right">
                                                        <Button>Edit</Button>
                                                    </TableCell>
                                                </TableRow>
                                            )
                                        )}

                                </TableBody>
                            </Table>
                        </TableContainer>
                    </Row>
                </Container>
            </div>
        );
    }
}
