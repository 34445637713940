import React from "react";
import {Button, Container, Navbar} from "react-bootstrap";
import {ServiceManager} from "../service/ServiceManager";

export default class AdminTopBar extends React.Component<any, any> {
    constructor(props: any) {
        super(props);
        this.logOut = this.logOut.bind(this);
    }

    render() {
        return <Navbar bg="light" expand="lg">
            <Container fluid>
                <div className="d-flex justify-content-center align-items-center ml-2 ml-lg-0">
                    <Navbar.Brand
                        href="/home"
                        className="mr-2">
                        Astroconsult
                    </Navbar.Brand>
                </div>
                <div className="d-flex justify-content-center align-items-center ml-2 ml-lg-0">
                    <Button variant="outline-danger" onClick={this.logOut}>Logout</Button>
                </div>
            </Container>
        </Navbar>;
    }

    logOut(evt: any) {
        evt.preventDefault();
        ServiceManager.getInstance().logout();
        window.location.replace("/login");
    }
}
