import React from "react";

export default class LicenseComponent extends React.Component<any,any>{
    render(): React.ReactNode {
        return <div>
            <h1>License Policy</h1>
            <p>Privacy Policies often disclose information about third-party services used by websites. It is important to disclose information about third-party usage because the Privacy Policies of third parties may differ from yours. Users need to know who has access and what their own unique policies are, since this may affect their data.</p>
            <ul>
                <li>Privacy Policies often disclose information about third-party services used by websites. It is important to disclose information about third-party usage because the Privacy Policies of third parties may differ from yours. Users need to know who has access and what their own unique policies are, since this may affect their data.</li>
                <li>Privacy Policies often disclose information about third-party services used by websites. It is important to disclose information about third-party usage because the Privacy Policies of third parties may differ from yours. Users need to know who has access and what their own unique policies are, since this may affect their data.</li>
                <li>Privacy Policies often disclose information about third-party services used by websites. It is important to disclose information about third-party usage because the Privacy Policies of third parties may differ from yours. Users need to know who has access and what their own unique policies are, since this may affect their data.</li>
                <li>Privacy Policies often disclose information about third-party services used by websites. It is important to disclose information about third-party usage because the Privacy Policies of third parties may differ from yours. Users need to know who has access and what their own unique policies are, since this may affect their data.</li>
                <li>Privacy Policies often disclose information about third-party services used by websites. It is important to disclose information about third-party usage because the Privacy Policies of third parties may differ from yours. Users need to know who has access and what their own unique policies are, since this may affect their data.</li>
                <li>Privacy Policies often disclose information about third-party services used by websites. It is important to disclose information about third-party usage because the Privacy Policies of third parties may differ from yours. Users need to know who has access and what their own unique policies are, since this may affect their data.</li>
                <li>Privacy Policies often disclose information about third-party services used by websites. It is important to disclose information about third-party usage because the Privacy Policies of third parties may differ from yours. Users need to know who has access and what their own unique policies are, since this may affect their data.</li>
            </ul>
        </div>;
    }
}