import React from "react";
import {ServiceManager} from "../service/ServiceManager";
import {toast} from "react-toastify";
import {Button, Col, Form, Row} from "react-bootstrap";
import {TextField} from "@mui/material";

export interface CustomerState {
    username: string;
    password: string;
    name: string;
}

export default class AddCustomerComponent extends React.Component<any, CustomerState> {

    constructor(props: any) {
        super(props);
        this.state = {
            name: "", password: "", username: ""
        }
        this.handleChange = this.handleChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
    }

    render() {
        return <div>
            <Form>
                <Row>
                    <Col>
                        <TextField label="name" name="name" value={this.state.name}
                                   onChange={this.handleChange}/>
                    </Col>
                    <Col>
                        <TextField label="username" name="username" value={this.state.username}
                                   onChange={this.handleChange}/>
                    </Col>
                    <Col>
                        <TextField label="password" name="password" value={this.state.password}
                                   onChange={this.handleChange}/>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <Button onClick={this.handleSubmit}>Create Customer</Button>
                    </Col>
                </Row>
            </Form>
        </div>;
    }

    handleChange = (e: any) => {
        e.preventDefault();
        this.setState({[e.target.name]: e.target.value} as unknown as CustomerState);
    }
    handleSubmit = () => {
        ServiceManager.getInstance().registerCustomer(this.state)
            .then(resp => resp.status === 200 ? toast.success(resp.data) : toast.error(resp.data))
            .catch(reason => toast.error(reason));
    }
}
