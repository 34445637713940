import React from "react";
import OnlinePujaComponent, {PujaProps, PujaState} from "./OnlinePujaComponent";
import {Container, Row, Col} from "react-bootstrap";
import {
    Pagination,
    Paper,
    Table,
    TableBody,
    TableCell,
    TableContainer, TableFooter,
    TableHead,
    TableRow,
    TextField
} from "@mui/material";
import {ServiceManager} from "../service/ServiceManager";
import SearchIcon from '@mui/icons-material/Search';

export interface PujaList {
    items: PujaState[];
    totalPages: number;
    currentPage: number;
    editItem: PujaState;
}

export interface SearchPayload {
    searchString: string;
}

export default class OnlinePujaListComponent extends React.Component<PujaProps, PujaList> {

    constructor(props: PujaProps) {
        super(props);
        this.state = {
            items: [],
            totalPages: 1,
            currentPage: 1,
            editItem: {
                id: 0,
                name: "",
                subTitle: "",
                duration: "",
                description: "",
                image: "",
                price: 0,
                dateAndTime: 0,
                showBefore: 0
            }
        };
        this.handlePageChange = this.handlePageChange.bind(this);
        this.tableRowClickHandler = this.tableRowClickHandler.bind(this);
        this.handleSearch = this.handleSearch.bind(this);
    }

    componentDidMount() {
        ServiceManager.getInstance().listPuja().then(resp => {
            //console.log(resp.data)
            this.setState({
                items: resp.data.items, totalPages: resp.data.totalPages, currentPage: 1, editItem: {
                    id: 0,
                    name: "",
                    subTitle: "",
                    duration: "",
                    description: "",
                    image: "",
                    price: 0,
                    dateAndTime: 0,
                    showBefore: 0
                }
            });
        });
    }

    handlePageChange(e: any, value: number) {
        ServiceManager.getInstance().sliceListPuja(value - 1).then(resp => {
            this.setState({items: resp.data.items, totalPages: resp.data.totalPages, currentPage: value});
        });
    }

    handleSearch(e: any) {
        e.preventDefault();
        let searchString: string = e.target.value;

        if (searchString.length > 3) {
            ServiceManager.getInstance().searchPuja({searchString: searchString})
                .then(resp => this.setState({
                    items: resp.data.items,
                    totalPages: resp.data.totalPages,
                    currentPage: 1
                }));
        }

        if (searchString.length === 0) {
            this.componentDidMount();
        }
    }

    render() {
        // @ts-ignore
        return <Container>
            <div>
                <Row>
                    <Col>
                        <h3>Puja List</h3>
                    </Col>
                    <Col className="p-2 ms-1" xs="8">

                        <TextField
                            variant="outlined"
                            label="Search"
                            onChange={this.handleSearch}
                        />
                        {/*<SearchIcon/>*/}
                    </Col>
                </Row>
                <TableContainer component={Paper} sx={{maxHeight: 440}}>

                    <Table sx={{minWidth: 650}} aria-label="simple table" stickyHeader>
                        <TableHead>
                            <TableRow>
                                <TableCell>Id</TableCell>
                                <TableCell>Name</TableCell>
                                <TableCell align="right">Subtitle</TableCell>
                                <TableCell align="right">Price</TableCell>
                                <TableCell align="right">Duration</TableCell>
                                <TableCell align="right">Description</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {this.state.items.map(row =>
                                <TableRow key={row.id} onClick={() => this.tableRowClickHandler(row)}>
                                    <TableCell component="th" scope="row">{row.id}</TableCell>
                                    <TableCell component="th" scope="row">{row.name}</TableCell>
                                    <TableCell align="right">{row.subTitle}</TableCell>
                                    <TableCell align="right">{row.price.toLocaleString()}</TableCell>
                                    <TableCell align="right">{row.duration}</TableCell>
                                    <TableCell align="right">{row.description}</TableCell>
                                </TableRow>
                            )}
                        </TableBody>
                        <TableFooter>
                            <Pagination shape="rounded" size="small" count={this.state.totalPages}
                                        page={this.state.currentPage}
                                        onChange={this.handlePageChange}/>
                        </TableFooter>
                    </Table>
                </TableContainer>

                {this.state.editItem.id === 0 ? <div></div> :
                    <OnlinePujaComponent id={this.state.editItem.id} key={this.state.editItem.id} callback={() => {
                        this.componentDidMount()
                    }}/>
                }
            </div>

        </Container>;
    }


    tableRowClickHandler(e: PujaState): void {
        this.setState({editItem: e});
        console.log(e)
    }
}
